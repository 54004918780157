import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryCaptionProps extends ComponentProps<'h2'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const FrontCoverstoryCaptionComponent: Component<FrontCoverstoryCaptionProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('caption_colors', colors, 'primary');
  const sizeClassName = propClassName('caption_size', size, 'default');
  const variantClassName = propClassName('caption_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <h2 className={componentClassName} {...props}>
      {children}
    </h2>
  );
};
