import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
  base: `text-gray-700 group-focus-within:text-black group-[&:hover:not([data-status=error])]:text-black group-data-[filled]:text-black group-data-[status=error]:text-error-600`,
  variants: {
    secondary: {
      true: `right-2.5 h-[22px] w-[22px]`,
    },
  },
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    input: `leading-6 outline-none ring-gray-700 transition-all text-preview-lg autofill:!shadow-white group-data-[status=error]:ring-2 group-data-[status=error]:ring-inset group-data-[status=error]:ring-error-600 hover:placeholder:text-black group-data-[status=error]:hover:ring-error-600 hover:not-focus:ring-black focus:ring-yves-klein-700`,
    caption: `text-gray-800 text-preview-sm group-data-[status=error]:text-error-600`,
  },
  variants: {
    variant: {
      primary: {
        label: `text-gray-800 text-label-md group-focus-within:text-yves-klein-500 group-data-[status=error]:text-error-600`,
        input: `h-10 px-2.5 pb-1.5 pt-2 peer-[.absolute]/icon:pr-7`,
      },
      secondary: {
        label: `absolute left-2 top-1/2 z-1 -translate-y-1/2 text-gray-700 transition-all text-preview-lg group-focus-within:top-1.5 group-focus-within:translate-y-0 group-focus-within:text-yves-klein-500 group-focus-within:text-label-md group-[&:hover:not(:focus-within):not([data-status=error])]:text-black group-data-[filled]:top-1.5 group-data-[filled]:translate-y-0 group-data-[status=error]:text-error-600 group-data-[filled]:text-label-md`,
        input: `h-[46px] w-full px-2.5 pb-1 pt-5 placeholder:opacity-0 autofill:!shadow-[inset_0_0_0px_1000px] peer-[.absolute]/icon:pr-8`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
