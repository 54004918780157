import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    captionGroup: 'text-preview-sm',
    byline: 'text-gray-600',
    group: 'not-prose',
  },
});

export default Image;
