import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { VideoTeaserMediaVideoComponent } from 'base/components/JwVideo/Teaser/VideoTeaser.Media.Video';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryVideoProps extends ComponentProps<'video'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
  videoURL: string;
}

export const FrontCoverstoryVideoComponent: Component<FrontCoverstoryVideoProps> = ({
  videoURL,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('video_colors', colors, 'primary');
  const sizeClassName = propClassName('video_size', size, 'default');
  const variantClassName = propClassName('video_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <VideoTeaserMediaVideoComponent
      className={componentClassName}
      thumbnail=""
      mediaId=""
      data-src={videoURL}
      {...props}
    />
  );
};
