/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "bg-none text-black hover:bg-gray-200 hover:text-black focus:bg-gray-200 focus:text-black focus:shadow-2 focus:shadow-yves-klein-700 active:shadow-none active:bg-gray-300 active:text-black",
  "colors_filled": "bg-black text-white focus:text-white focus:shadow-2 focus:shadow-yves-klein-700 active:bg-black active:text-white active:shadow-none",
  "colors_border": "text-black border border-black hover:bg-gray-200 hover:text-black hover:border-black focus:bg-gray-200 focus:text-black focus:border focus:border-black focus:shadow-2 focus:shadow-yves-klein-700 active:bg-gray-300 active:border active:border-black active:shadow-none active:text-black",
  "size_default": "w-8 h-8 [&_svg]:mt-0",
  "variant_default": "inline-flex items-center justify-center rounded-full focus:outline-none active:outline-none"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;