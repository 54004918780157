import { tw } from '@/utils/tw';
import BaseHamburgerTheme from 'base/components/Hamburger/theme';

const Hamburger = tw.theme({
  extend: BaseHamburgerTheme,
  slots: {
    base: `h-5.25 w-5.75`,
    segment: `rounded-null h-0.25 bg-black`,
  },
  variants: {
    animation: {
      primary: {
        segment: `last-of-type:translate-y-2.25 first-of-type:-translate-y-2.25`,
      },
    },
  },
});

export default Hamburger;
