import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryLogoProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const FrontCoverstoryLogoComponent: Component<FrontCoverstoryLogoProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('logo_colors', colors, 'black');
  const sizeClassName = propClassName('logo_size', size, 'default');
  const variantClassName = propClassName('logo_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
