import { OptionalLinkProps } from '@/components/OptionalLink';
import { componentTheme } from '@/styles/FrontCoverstory';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { StandaloneOptionalLink } from 'base/components/OptionalLink';
import { FrontCoverstoryCaptionComponent } from './FrontCoverstory.Caption';
import { FrontCoverstoryDescriptionComponent } from './FrontCoverstory.Description';
import { FrontCoverstoryGroupComponent } from './FrontCoverstory.Group';
import { FrontCoverstoryImageComponent } from './FrontCoverstory.Image';
import { FrontCoverstoryLogoComponent } from './FrontCoverstory.Logo';
import { FrontCoverstoryVideoComponent } from './FrontCoverstory.Video';

export type FrontCoverstoryProps = OptionalLinkProps;

const FrontCoverstoryComponent: StandaloneComponent<FrontCoverstoryProps> = (props) => {
  return (
    <StandaloneOptionalLink
      {...mergeProps(
        {
          options: {
            theme: componentTheme('link'),
          },
        },
        props,
      )}
    />
  );
};

export const FrontCoverstory = Object.assign(FrontCoverstoryComponent, {
  Caption: FrontCoverstoryCaptionComponent,
  Description: FrontCoverstoryDescriptionComponent,
  Group: FrontCoverstoryGroupComponent,
  Image: FrontCoverstoryImageComponent,
  Video: FrontCoverstoryVideoComponent,
  Logo: FrontCoverstoryLogoComponent,
});
