import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryGroupProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const FrontCoverstoryGroupComponent: Component<FrontCoverstoryGroupProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('group_colors', colors, 'primary');
  const sizeClassName = propClassName('group_size', size, 'default');
  const variantClassName = propClassName('group_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
