import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryDescriptionProps extends ComponentProps<'h3'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const FrontCoverstoryDescriptionComponent: Component<FrontCoverstoryDescriptionProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('description_colors', colors, 'primary');
  const sizeClassName = propClassName('description_size', size, 'default');
  const variantClassName = propClassName('description_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <h3 className={componentClassName} {...props}>
      {children}
    </h3>
  );
};
