import { Component } from '@/types/component';
import { cn } from '@/utils/cn';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import localFonts from 'next/font/local';

const fontPrimary = localFonts({
  display: 'swap',
  preload: true,
  src: [
    { path: '../../../public/fonts/ChronicleDisp-XLight_Web.woff2', weight: '275', style: 'normal' },
    { path: '../../../public/fonts/ChronicleDisp-XLightItalic_Web.woff2', weight: '275', style: 'italic' },
  ],
  variable: '--font-primary',
});

const fontSecondary = localFonts({
  display: 'swap',
  preload: true,
  src: [
    { path: '../../../public/fonts/elle-gabor.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/elle-gabor-medium.woff2', weight: '500', style: 'normal' },
    { path: '../../../public/fonts/elle-gabor-bold.woff2', weight: '700', style: 'normal' },
  ],
  variable: '--font-secondary',
});

const fontTertiary = localFonts({
  display: 'swap',
  preload: false,
  src: [
    { path: '../../../public/fonts/ChronicleSSm-Book_Web.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/ChronicleSSm-Bold_Web.woff2', weight: '600', style: 'normal' },
  ],
  variable: '--font-tertiary',
});

const fonts = cn(fontPrimary.variable, fontSecondary.variable, fontTertiary.variable);

export const ElleStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return <StandaloneEdge className={fonts}>{children}</StandaloneEdge>;
};
