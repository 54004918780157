import { ImageProps } from '@/components/Image';
import { propClassName } from '@/styles/FrontCoverstory';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { StandaloneImage } from 'base/components/Image';
import { cn } from '@/utils/cn';

export interface FrontCoverstoryImageProps extends ExtendedStandaloneComponentProps<ImageProps> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const FrontCoverstoryImageComponent: ExtendedStandaloneComponent<FrontCoverstoryImageProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('image_colors', colors, 'primary');
  const sizeClassName = propClassName('image_size', size, 'default');
  const variantClassName = propClassName('image_variant', variant, 'default');

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <StandaloneImage
      options={{
        className: componentClassName,
        ...standaloneOptions,
      }}
      {...standaloneProps}
      {...props}
    />
  );
};
