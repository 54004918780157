import { useStable } from '@/hooks/useStable';
import { StandaloneStateProviders } from 'base/components/StateProviders';
import { CoverstoryData } from 'base/components/labrador/Front/utils/injectCoverstory';

export const ElleStandaloneStateProviders: typeof StandaloneStateProviders = ({ children, ...pageProps }) => {
  const { pageData } = pageProps ?? {};
  const coverstory = pageData?.data?.coverstory as CoverstoryData | undefined;

  const appState = useStable(pageProps?.appState, { coverstory });

  return (
    <StandaloneStateProviders {...{ appState }} {...pageProps}>
      {children}
    </StandaloneStateProviders>
  );
};
