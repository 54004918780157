/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-black text-white border-black hover:bg-gray-900 focus:ring-gray-400",
  "colors_secondary": "bg-white border-black hover:bg-gray-200 focus:ring-2 focus:ring-gray-400",
  "size_small": "px-1.5 gap-0.75 pt-0.75 [&_svg]:mb-0.75 h-7 text-button-md",
  "size_medium": "px-2 gap-1.5 pt-0.75 [&_svg]:mb-0.75 h-8.5 text-button-md",
  "size_large": "px-4 gap-1.75 pt-0.75 [&_svg]:mb-0.75 h-10.5 text-button-md",
  "variant_default": "items-center justify-center relative rounded-md uppercase ring-0 focus:ring-2 focus-visible:outline-none active:ring-0 transition-all duration-200 ease-in-out border-1 flex-row inline-flex"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;